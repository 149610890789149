import axios from "axios"

export const HelpersApiMixin = {
  data() {
    return {
      // baseUrl: 'http://137.184.148.25/api', // Base URL for the API
      baseUrlBunny: 'https://br.storage.bunnycdn.com/padrao-color/artesclientes/', // Base URL for the API
    };
  },
  methods: {
    
    async axiosInstanceBunny(fileType) {
      // Retrieve the token from localStorage
      const AccessKey = '78103906-a8d5-4f08-bf2bd566e1e8-9a9f-49e6';
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrlBunny,
        headers: {
          'AccessKey': `${AccessKey}`,
          'Content-Type': `${fileType}`
        }
      });
    },
    async axiosInstanceViaCep(zipcode) {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: `https://viacep.com.br/ws/${zipcode}`,
      });
    },

    async axiosInstanceJadlog() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'http://206.189.182.138/api', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async axiosInstanceCielo() {
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: 'http://206.189.182.138/api', // Update with your Cloudflare Worker endpoint
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': 'ApiKey marketing@padraocolor.com.br:ef21f42b6ba995ec8ca6308a0c17f1ce3fa2507c',
        }
      });
    },

    async bunnyUpload(fileName, rawFile, fileType) {
      try {
        const instance = await this.axiosInstanceBunny(fileType);
        const response = await instance.put(`${fileName}`, rawFile);
        console.log("Upload successfully.", response.data);
      } catch (error) {
        console.error("Error upload", error);
      }
    },

    async getDataFromzipcode(zipcode) {
      try {
        const instance = await this.axiosInstanceViaCep(zipcode);
        const response = await instance.get('/json/');
        console.log("Upload successfully.", response.data);
        return response.data
      } catch (error) {
        console.error("Error upload", error);
      }
    },

    async getJadlogShippingData(jadlogData) {
      try {
        const instance = await this.axiosInstanceJadlog(jadlogData);
        const response = await instance.post('/fetch-jadlog-data/', jadlogData);
        console.log("Jadlog Shipping Data.", response.data);
        return response.data
      } catch (error) {
        console.error("Error upload", error);
      }
    },
    async getBalcoesJadlog(cep) {
      try {
        const instance = await this.axiosInstanceJadlog();
        const response = await instance.get('/jadlog-location/', {
          params: { cep: cep }
        });
        console.log("Balcões.", response.data);
        return response.data
      } catch (error) {
        console.error("Error upload", error);
      }
    },

    async cieloCreditCardTransaction(paymentData) {
      try {
        const instance = await this.axiosInstanceCielo();
        const response = await instance.post('/cielo-credit/', paymentData);
        console.log("Cielo.", response.data);
        return response.data
      } catch (error) {
        console.error("Error Cielo", error);
      }
    },

    
  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
