<template>
    <footer class="container py-5">
        <div class="footer-col">
            <h5>Informações</h5>
            <ul>
                <li>Item 1</li>
                <li>Item 2</li>
                <li>Item 3</li>
                <li>Item 4</li>
            </ul>
        </div>
        <div class="footer-col">
            <h5>Legal</h5>
            <ul>
                <li>Item 1</li>
                <li>Item 2</li>
                <li>Item 3</li>
                <li>Item 4</li>
            </ul>
        </div>
        <div class="footer-col">
            <h5>Features</h5>
            <ul>
                <li>Item 1</li>
                <li>Item 2</li>
                <li>Item 3</li>
                <li>Item 4</li>
            </ul>
        </div>
        <div class="footer-col">
            <h5>Contato</h5>
            <ul>
                <li class="d-flex align-items-start"><img class="mr-2" src="@/assets/icons/phone.png" />(21)3668-1550</li>
                <li class="d-flex align-items-start"><img class="mr-2" src="@/assets/icons/mail.png" /><span style="word-break: break-all;">sac@padraocolor.com.br</span></li>
                <li class="d-flex align-items-start"><img class="mr-2" src="@/assets/icons/mapa.png" />
                    <span>
                        Rua Nicolau Cheven, Nº 255 / São João – RJ<br>
                        Horário de Atendimento:<br>
                        Seg a sex - 8h as 18h
                    </span>
                </li>
            </ul>
        </div>
    </footer>
    <div class="bottom-footer">
        <div class="container row justify-content-between">
            <div class="copyright">
                <span>Padrão Color - Todos os Direitos Reservados</span>
                <br>
                <small>CNPJ: 14.572.530/0001-19 - Razão Social: APEL GRAFICA E EDITORA LTDA</small>
            </div>
            <div class="text-align-right d-flex align-items-center">
                <img class="icons-top-header mr-2" src="@/assets/icons/youtube.png" />
                <img class="icons-top-header mr-2" src="@/assets/icons/facebook.png" />
                <img class="icons-top-header" src="@/assets/icons/instagram.png" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
    .copyright {
        text-align: left;
        small {
            font-size:0.8rem;
        }
    }
    footer {
        display: flex !important;
        justify-content: center;
    }
    .footer-col {
        width: calc(90%/4);
        padding: 15px 10px 25px;
        text-align: left;
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                padding: 0;
                margin: 0;
            }
        }
    }
    .bottom-footer {
        display: flex;
        background: #FAFAFA;
        padding: 20px 10px;
        justify-content: center;
    }
    .hide-on-mobile {
        display: block !important;
    }
    .show-on-mobile {
        display: none !important;
    }
    @media screen and (max-width: 900px) {
        footer {
            flex-wrap: wrap;
        }
        .footer-col {
            width: 50%;
            padding: 15px 0;
            text-align: left;
        }
    }
    @media screen and (max-width: 490px) {
        .hide-on-mobile {
            display: block !important;
        }
        .show-on-mobile {
            display: none !important;
        }
        footer {
            flex-wrap:wrap;
            justify-content: flex-start;
        }
        .footer-col {
            width: 33%;
            padding: 15px 0;
            text-align: center;
        }
        .footer-col:last-child {
            width: 100%;
            text-align:left;
        }
    }
</style>