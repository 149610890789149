// stores/cartStore.js
import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification'

const toast = useToast();

export const useCartStore = defineStore('cart', {
  state: () => ({
    items: [],
  }),
  getters: {
    itemCount: (state) => state.items.length,
  },
  actions: {
    addItemOnLoadPage(item) {
      this.items.push(item);
    },
    addItem(item) {
        this.items.push(item);
        toast.success(`Produto adicionado ao carrinho`);
      },
    removeItem(index) {
    //   this.items = this.items.filter(item => item.id !== itemId);
    this.items.splice(index, 1);
    },
    clearCart() {
      this.items = [];
    },
  },
});