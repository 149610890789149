import axios from "axios"

export const ConfigDBMixin = {
  data() {
    return {
      baseUrl: 'http://68.183.102.37/api', // Base URL for the API
    };
  },
  methods: {
    async axiosInstance() {
      // Retrieve the token from localStorage
      //const token = localStorage.getItem('token');
      const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb2RpZ28iOjU3NX0.EbOdmP4T6j3exdk8YiLKG_86StglUm4OXQYqPawtaVo';
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },

    async getStoreConfig() {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.get(`/store-front/config`);
        console.log("Configurações da Loja.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getMenuItems() {
      try {
        let menuItems = []
        const instance = await this.axiosInstance();
        const response = await instance.get(`/store-front/config`);

        for (let i = 1; i <= 7; i++) {
          const menuCatId = `menu${i}_cat_id`;
          const menuCatName = `menu${i}_name`;
        
          // Check if both properties exist (avoid undefined values)
          if (response.data.storeConfig.hasOwnProperty(menuCatId) && response.data.storeConfig.hasOwnProperty(menuCatName)) {
            menuItems.push({
              id: response.data.storeConfig[menuCatId],
              nome: response.data.storeConfig[menuCatName],
            });
          }
        }
        console.log("Items do Menu.", menuItems);
        return menuItems
        // const filteredMenuItems = response.data.filter(item => item.tipo === "Menu");
        // console.log("Items do Menu.", response.data);
        // return filteredMenuItems;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
    async getByBusiness() {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.get(`/tiposnegocio`);
        console.log("Produtos por negocio.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
