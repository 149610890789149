import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from "./router"
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import VueNumberInput from '@chenfengyuan/vue-number-input';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toastOptions = {
    // You can set your default options here
};

const app = createApp(App)
    .use(router)
    .use(createPinia())
    .use(Toast, toastOptions)
    .component(VueNumberInput.name, VueNumberInput);
app.mount("#app");
