<template>
    <div class="modal-overlay" @click.self="closeModal">
      <div class="modal">
        <header class="modal-header">
        <!-- {{attachType}} -->
          <div name="header">
            <h3>{{ product.nome }}</h3>
          </div>
          <button class="close-button" @click="closeModal">&times;</button>
        </header>
  
        <section class="modal-body">
          <div>
              <img :src="product.imagem_1" alt="">
          </div>
          <div>
            <ul>
              <li v-for="(variacao, index) in product.variacoes"><label for="">{{index}}:</label> {{variacao}}</li>
            </ul>
            <div class="d-flex">
              <div class="product-production-type px-3">
                <h5>Prazo de liberação</h5>
                  <div>
                    <input class="mr-2" type="radio" name="prazo2" v-model="selectedProductionTime" value="economico">
                    <label for="economico">
                      <span>Econômico</span>
                      <small>({{ product.prazo_economico }} {{ product.prazo_economico === 1 ? 'dia útil' : 'dias úteis' }})</small>
                    </label>
                  </div>
                  <div>
                    <input class="mr-2" type="radio" name="prazo2" v-model="selectedProductionTime" value="expresso">
                    <label for="expresso">
                      <span>Expresso</span>
                      <small>({{ product.prazo_expresso }} {{ product.prazo_expresso === 1 ? 'dia útil' : 'dias úteis' }})</small>
                    </label>
                  </div>
              </div>
              <div v-if="product.tipo_customizacao == 1" class="product-file-handling px-3">
                <h5>Tratamento de Arquivo <img src="@/assets/icons/info.svg" alt="Padrão Color" /></h5>
                <div v-for="handle in fileHandle" :key="handle.name">
                  <input class="mr-2" type="radio" name="tratamento" v-model="selectedFileHandle" :value="handle.name">
                  <label :for="handle.name">{{handle.name}} ({{ formatCurrency(handle.price) }}{{ handle.price === 0 ? ' - Grátis' : '' }})</label>
                </div>
              </div>
            </div>
            <div v-if="product.tipo_customizacao == 1" class="product-art-type">
              <div class="product-art-type-input-container">
                <label>Arte:</label>
                <select v-model="selectedArtType" >
                  <option v-for="art in artType" :key="art.id" :value="art.id">{{ art.name }} {{ art.id === 2 ? '(' + productArtCreationPrice + ')' : '' }}</option>
                </select>
              </div>
              <div class="product-art-type-attach">
                <div v-if="selectedArtType == 1 && attachType[0] == 'Arquivo Único'">
                  <label>Arquivo único:</label>
                  <DropFile />
                  <template v-if="attachType.length > 1">
                    <p style="
                      text-align: center;
                      margin: 20px 0;
                      color: #ff4341;
                      font-size: 1.5rem;
                      font-weight: 600;">
                      ou
                    </p>
                    <div class="attach-multiple">
                      <div style="width:calc(50% - 20px); margin: 15px 0;" v-for="(item, index) in attachType.slice(1)" :key="index">
                        <label>{{item}}:</label>
                        <DropFile  />
                      </div>
                    </div>
                  </template>
                </div>
                <div v-if="selectedArtType == 2">
                  <div class="row mb-4">
                    <div class="col-12">
                      <label for="">Seu nome:</label>
                      <input type="text" class="form-control" placeholder="digite seu nome e sobrenome" >
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-sm-12 col-md-6">
                      <label for="">Email:</label>
                      <input type="text" class="form-control" placeholder="email@email.com" >
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <label for="">Telefone (whatsapp):</label>
                      <input type="text" class="form-control" placeholder="(xx) 99999-9999" >
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-sm-12 col-md-6">
                      <label for="">Arte de referência:</label>
                      <DropFile />
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <label for="">Seu logotipo:</label>
                      <DropFile2 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <footer class="modal-footer">
          <div style="display: flex; gap: 10px; width: 500px;">
            <button class="button button-primary mt-3">Adicionar ao carrinho</button>
          </div>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  import DropFile from "@/components/DropFile.vue";
  export default {
    components: {
      //ButtonComponent,
      DropFile
    },
    name: 'ModalComponent',
    emits: ['close', 'submit', 'customEvent'],
    props: {
        header: String,
        product: Object
    },
    data() {
        return {
          artType: [
            {id: 1, name:"Enviar minha arte"},
            {id: 2, name:"Solicitar Criação de arte"},
            {id: 3, name:"Criar Minha Arte Online"}
          ],
          artCreationPrice: null,
          selectedArtType: 1,
          productionRules: [
            {name: "economico", time: 0},
            {name: "expresso", time: 0}
          ],
          fileHandle: [
            {name:"Padrão", price:0},
            {name:"Profissional", price:21},
          ]
        }
    },
    computed: {
      productArtCreationPrice() {
        let price = 0
        if(this.product.criacao_arte) {
          price = parseFloat(this.product.criacao_arte_valor)
          price = this.formatCurrency(price)
        }
        
        return price
      },
      attachType() {
        let attach = []
        if(this.product.AttachmentTypes.length > 0) {
          for (const item of this.product.AttachmentTypes) {
            attach.push(item.nome)
          }
          
        }
        return attach
      }
    },
    methods: {
      formatCurrency(valor) {
          const valorFormatado = valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
          });
          return valorFormatado
      },
      closeModal() {
        this.$emit('close');
      },
      submitForm() {
        // Emit custom event with data
        this.$emit('customEvent', this.modalData);
        this.closeModal();
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    border-radius: 5px;
    padding: 20px;
    width: 90vw;
    z-index: 1001;
    display: block;
    max-width: 900px;
    position: relative;
    overflow: auto;
    text-align:left;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .attach-multiple {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .modal-body {
    margin-bottom: 20px;
    display: grid; 
    grid-template-columns: 1fr 1.5fr; 
    grid-template-rows: 1fr; 
    gap: 0px 10px; 
    grid-template-areas: 
      ". ."; 
    label {
      font-weight: 500;
    }
    img {
      max-width: 100%;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: left;
      }
    }
    .product-finishes, .product-file-handling {
      margin-top: 20px;
      h5 {
        font-size: 14px;
        font-weight: 500;
      }
      label {
        font-weight: 300;
      }
    }
    .product-production-type {
      margin-top: 20px;
      small {
        color: #a1a1a1;
        margin-left: 10px;
      }
      h5 {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .product-art-type {
      .product-art-type-input-container {
        display: flex;
        margin: 15px 0;
        label {
          width: 20%;
          font-weight: 500;
          font-size: 0.9rem;
        }
        select {
          flex: auto;
          border: 1px solid #D9D9D9;
          border-radius: 50px;
          font-size: 0.8em;
          padding: 5px 20px 5px 5px;
        }
      }        
    }
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  
  .default-button {
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .default-button:hover {
    background-color: #e1e1e1;
  }
  </style>
  