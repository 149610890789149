import axios from "axios"

export const CategoryDBMixin = {
  data() {
    return {
      baseUrl: 'http://web.padraocolor.com:9999/site', // Base URL for the API
    };
  },
  methods: {
    async axiosInstance() {
      // Retrieve the token from localStorage
      //const token = localStorage.getItem('token');
      const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb2RpZ28iOjU3NX0.EbOdmP4T6j3exdk8YiLKG_86StglUm4OXQYqPawtaVo';
      // Create an Axios instance with the Authorization header
      return axios.create({
        baseURL: this.baseUrl,
        headers: {
          'Authorization': `${token}`
        }
      });
    },

    async getAllCategories() {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.get(`/categorias`);
        console.log("Todas as categorias.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },

    async getCategoryGroups(name) {
      try {
        const instance = await this.axiosInstance();
        const response = await instance.get(`/categorias/?id=${name}`);
        console.log("Grupos da categoria.", response.data);
        return response.data;
      } catch (error) {
        console.error("Error loading data", error);
      }
    },
  },
  async created() {
    // Any initial API calls you need to make when the component is created
  },
};
