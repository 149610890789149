<template>
    <div class="highlights-container">
        <div class="column column1">
            <img :src="limitedItems[0].img" alt="">
        </div>
        <div class="column column2">
            <div class="image-wrapper">
                <img :src="limitedItems[1].img" alt="">
                <img :src="limitedItems[2].img" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            items: {
            type: Array,
            default: () => []
            }
        },
        data() {
            return {
            }
        },
        computed: {
            limitedItems() {
            // Limit the array to a maximum of 3 items
            return this.items.slice(0, 3);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .highlights-container {
        display: flex;
        .column {
            flex: 1;
        }

        .column1 {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .column2 {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        .column1 img {
            width: auto; /* Maintain aspect ratio */
            height: 100%; /* Ensure it fills the container */
        }

        .column2 .image-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%; /* Ensure the wrapper fills the height of the column */
            justify-content: space-between; /* Space images evenly */
        }

        .column2 img {
            width: auto; /* Maintain aspect ratio */
            height: 50%; /* Set to half of the first column image's height */
        }
    }
</style>