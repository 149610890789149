<template>
    <div class="container-full">
        <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="hide-on-mobile">
            <SplideSlide v-for="slide in imageslides" :key="slide">
                <img :src="slide.img" style="max-width: 100%" />
            </SplideSlide>
        </Splide>
        <ButtonTheme4 v-if="storeTheme == 4" />
        <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="show-on-mobile">
            <SplideSlide v-for="slide in bannerMobile[0]" :key="slide">
                <img :src="slide.imagem_mobile" style="max-width: 100%" />
            </SplideSlide>
        </Splide>
        <main class="container">
            <div class="row align-items-end hide-buttons-on-mobile mt-4">
                <template v-for="(img, index) in buttonsFirstLine" :key="img">
                    <div :class="['col-12', index === 0 ? 'col-md-6' : 'col-md-3']">
                        <img :src="img.imagem" style="max-width: 100%" />
                    </div>
                </template>
            </div>
            <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="show-on-mobile mx-2">
                <SplideSlide v-for="slide in botoesHome" :key="slide">
                    <img :src="slide" style="max-width: 100%" />
                </SplideSlide>
            </Splide>
            <ButtonTheme2 class="my-4" v-if="storeTheme == 2" />
            <HighLigthsTheme1 v-if="storeTheme == 1" :items="highligthCats" />
            <HighLigthsTheme2 v-if="storeTheme == 2" :items="highligthCats" />
            <HighLigthsTheme3 v-if="storeTheme == 3" :items="highligthCats" />
            <HighLigthsTheme4 v-if="storeTheme == 4" :items="highligthCats" />
            <section class="mt-5">
                <h2 class="section-title">Os mais vendidos</h2>
                <div class="row">
                    <div v-for="produto in highligthProducts" class="product-card">
                        <img v-if="isLoading" src="@/assets/icons/loading.svg" alt="Carregando" />
                        <img v-else :src="produto.foto1" />
                        <h5 class="my-2">{{produto.nome_exibicao}}</h5>
                        <div class="product-detail">
                            <template v-if="produto.Variations" v-for="(variacao, index) in produto.Variations">
                                <span class="variacoes">{{variacao.nome}}: {{variacao.descricao}}</span>
                            </template>
                            <template v-if="produto.Variacoes_lojas" v-for="(variacao, index) in produto.Variacoes_lojas">
                                <span class="variacoes">{{variacao.nome}}: {{variacao.descricao}}</span>
                            </template>
                            <div class="price-container">
                                <span class="price mt-2">{{formatCurrency(produto.preco_venda_economico)}}</span>
                                <span class="variacoes">{{produto.unidade}}</span>
                            </div>
                        </div>
                        <div class="button-container mt-4">
                            <div class="col-number">
                                <vue-number-input v-model="produto.initialQty" size="small" :min="1" inline controls></vue-number-input>
                            </div>
                            <div class="col-buybutton">
                                <button class="btn btn-primary" @click="openModal(produto)">Comprar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalComponent
                    v-if="isModalOpen"
                    header="Cadastrar Dados de Execução"
                    :product="selectedProduct"
                    @close="isModalOpen = false"
                >
                </ModalComponent>
            </section>
            <ButtonTheme1 class="my-4" v-if="storeTheme == 1" />
            <Splide :options="{ rewind: true }" aria-label="My Favorite Images" class="show-on-mobile mt-5">
                <SplideSlide v-for="slide in botoesLinha2" :key="slide">
                    <img :src="slide" style="max-width: 100%" />
                </SplideSlide>
            </Splide>
            <section class="mt-5">
                <h2 class="section-title">Nossos destaques</h2>
                <div class="row">
                    <div v-for="grupo in highligthGroups" class="product-group-card">
                        <router-link :to="{ name: 'produto', params: { groupId: grupo.id, groupType: grupo.origem, groupName: grupo.nome } }">
                            <img :src="grupo.imagem" />
                        </router-link>
                        <router-link class="flex-auto" :to="{ name: 'produto', params: { groupId: grupo.id, groupType: grupo.origem, groupName: grupo.nome } }">
                            <h5 class="my-2">{{grupo.nome}}</h5>
                        </router-link>
                        <router-link :to="{ name: 'produto', params: { groupId: grupo.id, groupType: grupo.origem, groupName: grupo.nome } }">
                            <div class="product-detail">
                                <small>A partir de:</small>
                                <span class="price">{{formatCurrency(grupo.preco)}}</span>
                            </div>
                        </router-link>
                    </div>
                </div>
                <router-link :to="{ name: 'produtos'}" class="btn btn-primary mt-5" style="width: 300px;">Todos os produtos</router-link>
            </section>
        </main>
        <Footer />
    </div>
</template>

<script>
    // If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import ModalComponent from '@/components/ModalComponent.vue'
    import Footer from '@/components/Footer.vue'
    import {ConfigDBMixin} from '@/database/config.js'
    import ButtonTheme1 from './Home/ButtonTheme1.vue';
    import ButtonTheme2 from './Home/ButtonTheme2.vue';
    import ButtonTheme4 from './Home/ButtonTheme4.vue';
    import HighLigthsTheme1 from './Home/HighLigthsTheme1.vue';
    import HighLigthsTheme2 from './Home/HighLigthsTheme2.vue';
    import HighLigthsTheme3 from './Home/HighLigthsTheme3.vue';
    import HighLigthsTheme4 from './Home/HighLigthsTheme4.vue';

    export default {
        name: 'App',
        mixins: [ConfigDBMixin],
        components: {
            Splide,
            SplideSlide,
            ModalComponent,
            ButtonTheme1,
            ButtonTheme2,
            ButtonTheme4,
            HighLigthsTheme1,
            HighLigthsTheme2,
            HighLigthsTheme3,
            HighLigthsTheme4,
            Footer
        },
        data() {
            return {
                storeTheme: 1,
                selectedProduct: null,
                isLoading: true,
                isModalOpen: false,
                buttonsFirstLine:[],
                buttonsSecondLine:[],
                imageslides: [],
                bannerMobile: [],
                highligthCats: [],
                highligthGroups: [],
                highligthProducts: [],
                botoesHome: [
                    'http://206.189.182.138/img/bt-mobile-3.png',
                    'http://206.189.182.138/img/bt-mobile1.png',
                    'http://206.189.182.138/img/bt-mobile-2.png'
                ],
            }
        },
        computed: {
            
        },
        methods: {
            openModal(produto) {
                this.selectedProduct = produto;
                this.isModalOpen = true;
            },
            formatCurrency(valor) {
                const valorFormatado = valor.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                });
                return valorFormatado
            },
            async getConfig() {
                const data = await this.getStoreConfig();

                this.storeTheme = data.storeConfig.theme

                console.log("data load getconfig", data)

                const bannerKeys = Object.keys(data).filter(key => key.startsWith('banner') && key.endsWith('_img'));
                bannerKeys.forEach(key => {
                    if (data[key]) {
                    this.imageSlides.push(data[key]);
                    }
                });
                
                // const filteredBannerItems = data.filter(item => item.tipo === "Banner");
                // const filteredButtonsFirstLineItems = data.filter(item => item.tipo === "Botões - Linha 1");
                // const filteredButtonsSecondLineItems = data.filter(item => item.tipo === "Botões - Linha 2");
                // const filteredHighLigthCatsItems = data.filter(item => item.tipo === "Categorias em Destaque");
                // const filteredHighLigthGroupsItems = data.filter(item => item.tipo === "Nossos Destaques");
                // const filteredHighLigthProductsItems = data.filter(item => item.tipo === "Produtos mais Vendidos");
                this.imageslides.push(...data.banners)
                // this.bannerMobile.push(filteredBannerItems)
                // this.buttonsFirstLine.push(...filteredButtonsFirstLineItems)
                // this.buttonsSecondLine.push(...filteredButtonsSecondLineItems)
                // this.highligthCats.push(...filteredHighLigthCatsItems)

                for (let i = 1; i <= 8; i++) {
                    const idKey = `minibanner_destaque_${i}`;
                    const imgKey = `minibanner_destaque_${i}_img`;
                    
                    if (data.storeConfig[idKey] !== undefined && data.storeConfig[imgKey] !== undefined) {
                        this.highligthCats.push({
                            id: data.storeConfig[idKey],
                            img: data.storeConfig[imgKey]
                        });
                    }
                }
                console.log("minibanners", this.highligthCats)

                this.highligthGroups.push(...data.groups)
                this.highligthProducts.push(...data.products)
                //hack to avoid empty value for the nome field
                this.highligthCats.forEach(element => {
                    if (element.nome == '') {
                        element.nome = 'teste'
                    }
                });
                // console.log("planos", data)
                // console.log("buttons 1 filter", filteredButtonsFirstLineItems)
                // console.log("buttons 1", this.buttonsFirstLine)
                console.log("Banner", this.imageslides)
                console.log("Categorias Destaque", this.highligthCats)
                console.log("Nossos Destaque", this.highligthGroups)
                console.log("Produtos mais Vendidos", this.highligthProducts)
                this.isLoading = false;
            }
        },
        mounted(){
            this.getConfig();
        },
    }
</script>
<style lang="scss">
    @import '@splidejs/vue-splide/css';
    @import '@/assets/styles/_variables.scss';
    
    // .carousel__slide {
    //     width: 100% !important;
    //     img {
    //         width: 100%;
    //     }
    // }
    .btn.btn-primary {
        background: var(--primary-color);
        color: #fff;
        border-radius: 50px;
        width: 100%;
        border: none;
        padding: 7px 15px;
        margin: 0;   
    }
    img {
        max-width: 100%;
    }
    section {
        h2.section-title {
            text-align: left;
        }
    }
    .container-full {
        max-width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
        img {
            max-width: 100%;
        }
    }

    .carousel__slide {
    padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
    }
    .grupo-produtos {
        margin-top: 100px;
        .grupo-produtos-col {
            width: calc(100%/5);
            padding: 0 1.5%;
        }
    }
    .product-group-card {
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .product-group-card {
        a:hover {
            color: var(--primary-color);
            text-decoration: none;
        }
    }
    .product-card {
        width: calc(90%/4);
        margin: 20px 1%;
        padding: 15px 10px 25px;
        background: #fff;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h5 {
            font-weight: bold;
        }
        img {
            //max-height: 175px;
        }
        .product-detail {
            flex: auto;
            display: flex;
            flex-direction: column;
        }
        .variacoes {
            font-size: 11px;
        }
        .button-container {
            display: flex;   
        }
        .price-container {
            flex: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        .price {
            font-size: 22px;
            font-weight: 500;
        }
        span {
            display: block;
        }
        .btn-primary {
            background: var(--primary-color);
            color: #fff;
            border-radius: 50px;
            width: 100%;
            border: none;
            padding: 7px 15px;
            margin: 0;   
        }
        .btn-primary:hover {
            background: #D98700;
        }
        .btn-primary:active {
            background: #D98700;
        }
    }
    .product-group-card {
        width: calc(90%/4);
        margin: 20px 1%;
        padding: 15px 10px 25px;
        background: #fff;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        h5 {
            font-weight: bold;
        }
        .flex-auto {
            flex: auto;
        }
        .product-detail {
            flex: auto;
            display: flex;
            flex-direction: column;
        }
        
        .price {
            font-size: 22px;
            font-weight: 500;
        }
    }
    .col-buybutton {
        width: calc(60% - 10px);
        margin-left: 10px;
    }
    .col-number {
        width: 40%;
        .vue-number-input {
            height: 38px;
        }
        .vue-number-input__input {
            height: 38px;
        }
        .vue-number-input {
            border-radius: 30px;
            border: 1px solid #ccc;
        }
        .vue-number-input__button {
            width: 1rem !important;
        }
        .vue-number-input__button::before {
            width: 8px !important;
            background-color: var(--primary-color) !important;
        }
        .vue-number-input__button::after {
            height: 8px !important;
            background-color: var(--primary-color) !important;
        }
        .vue-number-input--small.vue-number-input--controls > input {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
            text-align: center;
        }
    }
    .hide-on-mobile {
        display: block !important;
    }
    .show-on-mobile {
        display: none !important;
    }
    .hide-buttons-on-mobile {
        display: flex !important;
    }
    .hide-on-mobile-flex {
        display: flex;
    }
    
    @media screen and (max-width: 900px) {
        .header-links {
            align-items: center;
            line-height: 15px;
        }
        .product-card, .product-group-card {
            width: 23%;
            margin: 10px 1%;
        }
        .hide-on-mobile-flex {
            display: flex;
        }
        .product-card{
            .button-container {
                display: block;
                .col-number {
                    width: 100%;
                }
                .col-buybutton {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
    @media screen and (max-width: 490px) {
        .mobile-mt-2 {
            margin-top: 2rem;
        }
        .hide-on-mobile-flex {
            display: none !important;
        }
        .hide-on-mobile, .hide-buttons-on-mobile{
            display: none !important;
        }
        .show-on-mobile {
            display: block !important;
        }
        .product-card, .product-group-card {
            width: 48%;
            margin: 10px 1%;
        }
        .product-group-card:last-child {
            display: none;
        }
        .product-card{
            .button-container {
                display: block;
                .col-number {
                    width: 100%;
                }
                .col-buybutton {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
        .grupo-produtos {
            justify-content: center;
            margin-top: 50px;
            .grupo-produtos-col {
                width: 50%;
                padding: 10px;
            }
            .grupo-produtos-col:last-child {
                display: none;
            }
        }
    }
</style>