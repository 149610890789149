<template>
  <div class="row align-items-center my-4 container hide-nav-on-mobile" style="margin: 0 auto; padding-left: 0;">
    <div class="col-header logo-container">
        <router-link :to="{ name: 'home' }">
            <img v-if="storeConfig" class="logo-nav" :src="storeConfig.storeConfig.logo" alt="">
        </router-link>
    </div>
    <div class="col-header search-container">
        <div class="input-group mb-3 search">
            <input 
                type="text"
                class="form-control"
                placeholder="Digite o nome do produto"
                aria-label="Group Name"
                aria-describedby="button-addon2"
                @keyup.enter="searchGroup"
                v-model="groupName"
                >
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="searchGroup">Buscar</button>
            </div>
        </div>
    </div>
    <div class="col-header d-flex header-links">
        <router-link class="mr-3" :to="{ name: 'helpcenter', params: { tab: 1 } }">
            Ajuda
        </router-link>
        <!-- <router-link :to="{ name: 'login' }" class="mr-3">Minha Conta</router-link> -->
        <div v-if="!loggedIn">
            <router-link class="dropdown-item" :to="{ name: 'login' }">
                Minha Conta
            </router-link>
        </div>
        <a v-else class="nav-link dropdown-toggle" href="#" id="negocio" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-weight:300; margin: 0 10px 0 0; padding:0;">
            <img class="icon" src="@/assets/icons/user-alt.svg" alt="Padão Color" />
            {{loggedUser}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">    
            <router-link class="dropdown-item" :to="{ name: 'userdata' }">
                Meus Dados
            </router-link>
            <router-link class="dropdown-item" :to="{ name: 'usercompras' }">
                Minhas Compras
            </router-link>
            <router-link class="dropdown-item" :to="{ name: 'home' }" @click="logout">
                Sair
            </router-link>
        </div>
        
        <router-link :to="{ name: 'cart' }" style="position: relative;">
            <img class="icons-nav" src="@/assets/icons/cart.png" />
            <div class="cart-pushup" v-if="itemCount">
                <span>{{ itemCount }}</span>
            </div>
        </router-link>
    </div>
  </div>
    <nav class="navbar navbar-expand-lg container">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <a class="navbar-brand" href="#" @click="toggleSidebar()">
                    <img src="@/assets/icons/menu.png" />
                </a>
                <li class="nav-item dropdown">
                    <a
                        v-for="item in megaMenuItems"
                        @click="mouseEvent($event, 'item', item.key)"
                        :key="item.key"
                        class="nav-link dropdown-toggle"
                    >
                        {{ item.label }}
                    </a>
                </li>
                <!-- <li class="nav-item dropdown" >
                    <a class="nav-link dropdown-toggle" href="#" id="negocio" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Para o seu negócio
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <template v-for="item in byBusiness">
                            <router-link class="dropdown-item" :to="{ name: 'seunegocio', params: { categoryId: item.id, categoryName: item.nome } }">
                                {{item.nome}}
                            </router-link>
                        </template>
                    </div>
                </li> -->
                
                <li class="nav-item dropdown" v-for="item in menuItems">
                    <a class="nav-link dropdown-toggle" href="#" :id="item.none" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{item.nome}}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <template v-for="grupo in item.grupos">
                            <router-link class="dropdown-item" :to="{ name: 'produto', params: { groupId: grupo.id, groupName: grupo.nome } }">
                                {{grupo.nome}}
                            </router-link>
                        </template>
                    </div>
                </li>
                
            </ul>
        </div>
        <div :class="['sidebar', { 'is-collapsed': isCollapsed }]">
            <div class="sidebar-content">
                <img src="https://padraocolor.com.br/assets/images/logobrancopadrao.png" alt="Padrãocolor" class="w-100 px-5 mb-4" style="width: 100px;">
                <strong>Institucional</strong>
                <ul>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'aboutus'}">
                            Sobre Nós
                        </router-link>
                    </li>
                    <!-- <li>Padrão na Imprensa</li> -->
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'policies'}">
                            Política de Privacidade
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'ourstores'}">
                            Nossas Lojas
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'contact'}">
                            Contatos
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'trabalheconosco'}">
                            Faça parte de nossa equipe
                        </router-link>
                    </li>
                </ul>
                <strong>Informações</strong>
                <ul>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'helpcenter', params: { tab: 1 }}">
                            Central de Ajuda
                        </router-link>
                    </li>
                    <li>Tutoriais</li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'downloads'}">
                            Downloads
                        </router-link>
                    </li>
                    <li>Pontos de retirada</li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'helpcenter', params: { tab: 2 }}">
                            Montagem de Arte
                        </router-link>
                    </li>
                </ul>
                <strong>Conteúdo</strong>
                <ul>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'glossario'}">
                            Glossário
                        </router-link>
                    </li>
                    <li>Instagram</li>
                    <li>Facebook</li>
                    <li>Blog</li>
                    <li>Dicas e Tutoriais</li>
                </ul>
                
                <strong>Utilidades</strong>
                <ul>
                    <li>Projetos Especiais</li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'helpcenter', params: { tab: 4 }}">
                            Normas de Envio de Arte
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'helpcenter', params: { tab: 5 }}">
                            Entrega Personalizada para seu cliente
                        </router-link>
                    </li>
                    <li>System Color</li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'gabaritos'}">
                            Gabaritos
                        </router-link>
                    </li>
                </ul>
                <strong>Comercial</strong>
                <ul>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'vendascorporativas'}">
                            Venda Corporativa
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'sejafornecedor'}">
                            Seja um fornecedor
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="toggleSidebar" :to="{ name: 'resellercategories'}">
                            Beneficios de nossas categorias
                        </router-link>
                    </li>
                </ul>
            </div>
            <button @click="toggleSidebar()">
                .
            </button>
        </div>
        <!-- <div class="items">
            <a
            v-for="item in megaMenuItems"
            @mouseover="mouseEvent($event, 'item', item.key)"
            :key="item.key"
            class="item"
            >
            {{ item.label }}
            </a>
        </div> -->
    </nav>
    <MegaMenu v-if="showMegaMenu" />
  
  <nav class="show-on-mobile" style="margin: 0 auto;">
    <div class="row align-items-center mt-4 mb-3 container">
        <div class="col-4 logo-container">
            <router-link :to="{ name: 'home' }">
                <img class="logo-nav" src="https://padraocolor.com.br/assets/images/logo-padrao-color.png" />
            </router-link>
        </div>
        <div class="col-8 d-flex header-links p-0">
            <a href="#" class="mr-3">Ajuda</a>
            <a href="#" class="mr-3">Minha Conta</a>
            <img class="icons-nav" src="@/assets/icons/cart.png" />
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center my-2">
        <span class="mr-2" style="font-size: 11px;">Siga nossas redes: </span>
        <img class="icons-top-header mr-2" src="@/assets/icons/youtube.png" />
        <img class="icons-top-header mr-2" src="@/assets/icons/facebook.png" />
        <img class="icons-top-header" src="@/assets/icons/instagram.png" />
    </div>
    <div class="input-group px-4 mb-3 search">
        <input type="text" class="form-control" placeholder="Digite o nome do produto" aria-label="Recipient's username" aria-describedby="button-addon2">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2"><img  src="@/assets/icons/lupa.png" /></button>
        </div>
    </div>
    <nav class="d-flex justify-content-between mt-2 mb-4 container show-nav-on-mobile">
        <img src="@/assets/icons/menu.png" />
        <div class="btn-group nav-dropdown">
            <button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Todos os produtos
            </button>
            <div class="dropdown-menu dropdown-menu-right">
            <p>Item 1</p>
            <div class="dropdown-divider"></div>
            <p>Item 1</p>
            <div class="dropdown-divider"></div>
            
            </div>
        </div>
        <div class="btn-group nav-dropdown">
            <button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Para o seu negócio
            </button>
            <div class="dropdown-menu dropdown-menu-right">
            <p>Item 1</p>
            <div class="dropdown-divider"></div>
            <p>Item 1</p>
            <div class="dropdown-divider"></div>
            
            </div>
        </div>
    </nav>
  </nav>
</template>

<script>
    import {ConfigDBMixin} from '@/database/config.js'
    import MegaMenu from '@/components/MegaMenu.vue'
    import eventBus from "../eventBus";
    import { inject } from 'vue'
    import { mapState, mapGetters, mapActions } from 'pinia';
    import { useCartStore } from '../stores/cartStore';

    export default {
        mixins: [ConfigDBMixin],
        components: {
            MegaMenu
        },
        data() {
            return {
                groupName: "",
                isCollapsed: true,
                loggedUser: '',
                userToken: null,
                storeConfig: null,
                menuItems: [],
                //byBusiness: [],
                megaMenuItems: [
                    { key: "products", label: "Todos os Produtos" },
                    
                ],
                showMegaMenu: false,
            }
        },
        computed: {
            ...mapState(useCartStore, ['items']),
            ...mapGetters(useCartStore, ['itemCount']),
            loggedIn() {
                
                if (localStorage.getItem("user_token")) {
                    console.log("Tem")
                    this.userToken = localStorage.getItem("user_token")
                    this.loggedUser = localStorage.getItem("user")
                } else {
                    console.log("Não Tem")
                }
                
                return this.userToken
            }
        },
        methods: {
            ...mapActions(useCartStore, ['removeItem']),
            ...mapActions(useCartStore, ['addItem']),
            ...mapActions(useCartStore, ['addItemOnLoadPage']),
            ...mapActions(useCartStore, ['clearCart']),
            searchGroup() {
                this.$router.push({ path: `/busca/${this.groupName}` });
            },
            toggleSidebar() {
                this.isCollapsed = !this.isCollapsed
            },
            logout() {
                localStorage.removeItem("user_token");
                localStorage.removeItem("user");
                this.userToken = null
                // this.$router.push({ path: `/` });
            },
            mouseEvent(event, source, key = "") {
                if (source === "item") {
                    event.stopPropagation();
                }
                this.showMegaMenu = key === "products";
            },
            async getMenu() {
                const data = await this.getMenuItems();
                this.menuItems = data
                console.log("menu carregado", this.menuItems)
            },
            async loadStoreConfig() {
                const data = await this.getStoreConfig();
                this.storeConfig = data
                console.log("configurações da loja", this.storeConfig)
            },
            // async loadByBusiness() {
            //     const data = await this.getByBusiness();
            //     this.byBusiness.push(...data)
            //     console.log("por negocio", this.byBusiness)
            // },
        },
        mounted(){
            eventBus.on('hide-mega-menu', () => {
                this.showMegaMenu = false;
            });
            this.getMenu();
            this.loadStoreConfig();
            if (localStorage.getItem("cart")) {
                const getCartItens = localStorage.getItem('cart')
                const parsedCartItens = JSON.parse(getCartItens)
                // this.cartItems = parsedCartItens.length
                this.clearCart()
                for (const item of parsedCartItens) {
                    this.addItemOnLoadPage(item);
                }
                
            }
        },
        // beforeDestroy() {
        //     eventBus.$off("hide-mega-menu");
        // },
    }
</script>
<style lang="scss">
    @import '@/assets/styles/_variables.scss';
    
    .logo-nav {
        width: 145px;
        max-width: 100%;
    }
    .nav {
        display: flex;
    }
    .nav-link.dropdown-toggle {
        font-weight: 500;
    }
    .header-links {
        justify-content: flex-end;
        a {
            color: #000
        }
    }
    .logo-container {
        text-align: left;
    }
    .dropdown-toggle {
        color: #000;
        .icon {
            width: 15px;
        }
    }
    .cart-pushup {
        position: absolute;
        top: -9px;
        right: -17px;
        width: 20px;
        height: 20px;
        background: var(--primary-color);
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        border-radius: 100%;
        span {
            font-weight: 700;
            display: block;
            margin-top: 1px;
        }
    }
    .col-header.logo-container {
        margin-right: 50px;
    }
    .col-header.search-container {    
        flex: 1;
        margin-right: 50px;
    }
    .navbar-nav {
        flex-wrap: wrap;
    }
    .sidebar {
        &.is-collapsed {
            display:none;
        }
        display:flex;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.7);
        width: 100%;
        height: 100%;
        z-index: 999;
        button {
            flex: auto;
            background: transparent;
            border: none;
        }
        .sidebar-content {
            width: 300px;
            height: 100%;
            background: #a50505;
            color: #fff;
            text-align: left;
            padding: 10px 20px;
            overflow-y: auto;
            strong {
                font-weight: 600;
            }
            ul {
                list-style: none;
                padding-left: 25px;
            }
            li a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
    .search {
        input {
            height: 45px;
            border-right: 0;
            border-radius: 30px 0 0 30px;
        }
        .input-group-append {
            background: #fff;
            padding: 4px;
            height: 45px;
            border: 1px solid #ced4da;
            border-radius: 0 30px 30px 0;
        }
        .btn.btn-outline-secondary {
            background: var(--primary-color);
            color: #fff;
            border-radius: 50px;
            border: none;
            padding: 0 30px;
        }
    }
    .btn-group.nav-dropdown {
        .dropdown-toggle {
            border: none;
            background: transparent;
            box-shadow: none;
        }
    }
    .hide-nav-on-mobile {
            display: flex !important;
        }
        .show-nav-on-mobile {
            display: none !important;
        }
    @media screen and (max-width: 490px) {
        .mobile-mt-2 {
            margin-top: 2rem;
        }
        .hide-nav-on-mobile {
            display: none !important;
        }
        .show-nav-on-mobile {
            display: flex !important;
        }
        .search .btn.btn-outline-secondary {
            padding: 0 15px;
        }
    }
</style>