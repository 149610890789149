<template>
    <div class="highlights-container">
        <img v-for="item in limitedItems" :key="item.id" :src="item.img" alt="">
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            items: {
            type: Array,
            default: () => []
            }
        },
        data() {
            return {
            }
        },
        computed: {
            limitedItems() {
            // Limit the array to a maximum of 3 items
            return this.items.slice(0, 4);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .highlights-container {
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 15px; 
        grid-template-areas: 
            ". . . ."; 
        img {
            border-radius: 100%;
        }
    }
</style>