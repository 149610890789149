<template>
    <div class="top-header">
        <!-- <a v-if="topHeader.image" :href="topHeader.url">
            <img :src="topHeader.image" />
        </a> -->
        <div class="row container my-4" style="margin: 0 auto;">
            <div class="col-md-6 d-flex align-items-center">
                <img class="icons-nav mr-2" src="@/assets/icons/projetos-especiais.png" />
                <span style="color: #000;">Projetos Especiais: Faça seu produto sob medida</span>
            </div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">
                <span class="mr-2">Siga nossas redes: </span>
                <img class="icons-top-header mr-2" src="@/assets/icons/youtube.png" />
                <img class="icons-top-header mr-2" src="@/assets/icons/facebook.png" />
                <img class="icons-top-header" src="@/assets/icons/instagram.png" />
            </div>
        </div>
    </div>
</template>

<script>
    import {ConfigDBMixin} from '@/database/config.js'
    export default {
        mixins: [ConfigDBMixin],
        components: {
        },
        data() {
            return {
                topHeader:{
                    image:'',
                    url:''
                }
            }
        },
        methods: {
            async getConfig() {
                const data = await this.getStoreConfig();
                //const filteredBannerItems = data.filter(item => item.tipo === "TopHeader");
                // this.topHeader.image= filteredBannerItems[0].imagem
                // this.topHeader.url= filteredBannerItems[0].url
            }
        },
        mounted() {
            this.getConfig()
        },
    }
</script>
<style lang="scss" scoped>
    .icons-top-header {
        // width: 34px;
    }
    .top-header {
        border-bottom: 1px solid #D9D9D9;
    }
</style>