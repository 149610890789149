<template>
  <div class="main">
    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        
        name="file"
        :id="uniqueId"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <label :for="uniqueId" class="file-label">
        <img src="@/assets/icons/file.png" alt="Padão Color - Envio de Arte" />
        <div v-if="isDragging">Solte seu arquivo aqui.</div>
        <div v-else>Arraste seu arquivo aqui ou <span>selecione</span> para fazer o upload.</div>
      </label>

      <div class="preview-container mt-4" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div class="preview-card-img-container">
            <img class="preview-img" :src="generateThumbnail(file)" />
            <p :title="file.name">
              {{ makeName(file.name) }}
            </p>
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <b>&times;</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {HelpersApiMixin} from '@/database/helpers.js'

export default {
  mixins: [HelpersApiMixin],
  props: {
      attachType: String,
  },
  data() {
    return {
      isDragging: false,
      files: [],
      uniqueId: 'fileInput-' + Math.random().toString(36).substr(2, 9)
    };
  },
  methods: {
    uploadFiles() {
        const files = this.files;
        const formData = new FormData();
        files.forEach((file) => {
            // formData.append("selectedFiles", file);
            const timestampedName = this.appendTimestampToFileName(file.name);
            this.bunnyUpload(timestampedName, files[0], file.type);
            this.$emit('file-uploaded', timestampedName, this.attachType);
        });
        console.log("arquivo", files);
    },
    onChange() {
      this.files = [...this.$refs.file.files];
      this.uploadFiles()
    },
    appendTimestampToFileName(fileName) {
      const timestamp = Date.now();
      const dotIndex = fileName.lastIndexOf('.');
      if (dotIndex === -1) {
        // No extension
        return `${fileName}-${timestamp}`;
      } else {
        const name = fileName.substring(0, dotIndex);
        const extension = fileName.substring(dotIndex);
        return `${name}-${timestamp}${extension}`;
      }
    },

    generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },

    makeName(name) {
      return (
        name.split(".")[0].substring(0, 3) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },

    remove(i) {
      this.files.splice(i, 1);
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

/* .main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
} */
.dropzone-container {
  padding: 2rem;
    background: #EFEFEF;
    border-radius: 15px;
    border: 1px solid #D9D9D9;
    color: #878787;
  
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 15px;
    display: block;
    cursor: pointer;
    text-align: center;
    span {
        color: var(--primary-color);
        text-decoration: underline
    }
}
.preview-container {
  display: flex;
  margin-top: 2rem;
}
.preview-card {
  display: flex;
  padding: 5px;
  margin-left: 5px;
  width: 100%;
  .preview-card-img-container {
    flex: auto;
  }
  button {
    border: 0;
    border-radius: 7px;
    font-weight: 900;
    font-size: 20px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    line-height: 20px;
  }
}
.preview-img {
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>