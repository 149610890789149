import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";


const Categoria = () => import("@/components/Categoria.vue")
const Grupo = () => import("@/components/Group.vue")
const Groups = () => import("@/components/Groups.vue")
const GroupsComponent = () => import("@/components/GroupsComponent.vue")
// const GrupoTeste = () => import("@/components/GroupTeste.vue")
const Login = () => import("@/views/LoginView.vue")
const Register = () => import("@/views/RegisterView.vue")
const Cart = () => import("@/components/Cart.vue")
const Checkout = () => import("@/components/Checkout.vue")
const UserData = () => import("@/components/UserDashboard/UserData.vue")
const UserCompras = () => import("@/components/UserDashboard/UserCompras.vue")
const UserDetalhesCompra = () => import("@/components/UserDashboard/UserDetalhesCompra.vue")
//PÁGINAS INSTITUCIONAIS
const ResellerCategories = () => import("@/components/Institucional/ResellerCategories.vue")
const PageAboutUs = () => import("@/components/Institucional/AboutUs.vue")
const PageHelpCenter = () => import("@/components/Institucional/HelpCenter.vue")
const PagePolicies = () => import("@/components/Institucional/Policies.vue")
const PageGlossario = () => import("@/components/Institucional/Glossario.vue")
const PageVendasCorporativas = () => import("@/components/Institucional/VendasCorporativas.vue")
const PageSejaFornecedor = () => import("@/components/Institucional/SejaFornecedor.vue")
const PageTrabalheConosco = () => import("@/components/Institucional/TrabalheConosco.vue")
const PageDownloads = () => import("@/components/Institucional/Downloads.vue")
const PageContato = () => import("@/components/Institucional/Contato.vue")
const OurStores = () => import("@/components/Institucional/OurStores.vue")
const Gabaritos = () => import("@/views/Institucional/GabaritosView.vue")
const Web2print = () => import("@/components/PitchPrint.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      topheader: false,
      navigation: false,
    },
  },
  {
    path: "/login/:redirect",
    name: "loginredirect",
    component: Login,
    meta: {
      topheader: false,
      navigation: false,
    },
  },
  {
    path: "/criar-conta/:redirect",
    name: "registerAuth",
    component: Register,
    meta: {
      topheader: false,
      navigation: false,
    },
  },
  {
    path: "/categoria/:categoryId",
    name: "categoria",
    component: Categoria,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/para-seu-negocio/:categoryId",
    name: "seunegocio",
    component: Categoria,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    //path: "/produto/:groupName",
    path: "/produto/:groupId/:groupName/:groupType",
    name: "produto",
    component: Grupo,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    //path: "/produto/:groupName",
    path: "/todos-os-produtos",
    name: "produtos",
    component: Groups,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/busca/:groupName",
    name: "searchResult",
    component: GroupsComponent,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/carrinho",
    name: "cart",
    component: Cart,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/finalizar-compra",
    name: "checkout",
    component: Checkout,
    meta: {
      topheader: false,
      navigation: false,
    },
  },
  {
    path: "/minha-conta/meus-dados",
    name: "userdata",
    component: UserData,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/minha-conta/compras",
    name: "usercompras",
    component: UserCompras,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/minha-conta/detalhes-da-compra/:purchaseId",
    name: "userdetalhescompra",
    component: UserDetalhesCompra,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/nossas-categorias-de-revenda",
    name: "resellercategories",
    component: ResellerCategories,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/sobre-nos",
    name: "aboutus",
    component: PageAboutUs,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/central-de-ajuda/:tab",
    name: "helpcenter",
    component: PageHelpCenter,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/politicas",
    name: "policies",
    component: PagePolicies,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/glossario",
    name: "glossario",
    component: PageGlossario,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/vendas-corporativas",
    name: "vendascorporativas",
    component: PageVendasCorporativas,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/seja-um-fornecedor",
    name: "sejafornecedor",
    component: PageSejaFornecedor,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/faca-parte-da-equipe",
    name: "trabalheconosco",
    component: PageTrabalheConosco,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/downloads",
    name: "downloads",
    component: PageDownloads,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/institucional/nossas-lojas",
    name: "ourstores",
    component: OurStores,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/contato",
    name: "contact",
    component: PageContato,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/gabaritos",
    name: "gabaritos",
    component: Gabaritos,
    meta: {
      topheader: true,
      navigation: true,
    },
  },
  {
    path: "/web2print",
    name: "web2print",
    component: Web2print,
    meta: {
      topheader: true,
      navigation: true,
    },
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the route has a savedPosition (e.g., back/forward navigation)
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   // Scroll to the top of the page
    //   return { top: 0 }
    // }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0, behavior: 'smooth' });
      }, 300); // Adjust delay for desired transition speed
    });
  }
});

export default router;