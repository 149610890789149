<template>
    <div class="buttons-home-container">
        <img src="@/assets/images/button1-home-theme2.png" alt="">
        <img src="@/assets/images/button2-home-theme2.png" alt="">
        <img src="@/assets/images/button3-home-theme2.png" alt="">
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
            }
        },
    }
</script>
<style lang="scss" scoped>
    .buttons-home-container {
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 15px; 
        grid-template-areas: 
            ". . ."; 
    }
</style>