<template>
  <!-- <TopHeader v-if="$route.meta.topheader != false" class="hide-on-mobile" /> -->
  <Navigation v-if="$route.meta.navigation != false" />
  <MegaMenu />
  <router-view />
</template>

<script>
import Home from './components/Home.vue'
import TopHeader from './components/TopHeader.vue'
import Navigation from './components/Navigation.vue'
import {ConfigDBMixin} from '@/database/config.js'
import { useRouter } from 'vue-router'

export default {
  name: 'App',
  mixins: [ConfigDBMixin],
  components: {
    TopHeader,
    Home,
    Navigation,
  },
  methods: {
    async getConfig() {
      const data = await this.getStoreConfig();
      if (data.storeConfig.theme == 1) {
        console.log("theme 1 loaded")
        this.loadTheme1Style()
      }

      if (data.storeConfig.theme == 2) {
        console.log("theme 2 loaded")
        this.loadTheme2Style()
      }

      if (data.storeConfig.theme == 3) {
        console.log("theme 3 loaded")
        this.loadTheme3Style()
      }

      if (data.storeConfig.theme == 4) {
        console.log("theme 4 loaded")
        this.loadTheme4Style()
      }
    },

    async loadTheme1Style() {
      const style = await import('@/assets/styles/theme1.scss');
    },

    async loadTheme2Style() {
      const style = await import('@/assets/styles/theme2.scss');
    },

    async loadTheme3Style() {
      const style = await import('@/assets/styles/theme3.scss');
    },

    async loadTheme4Style() {
      const style = await import('@/assets/styles/theme4.scss');
    }
  },
  mounted() {
    this.getConfig();
  }
}
</script>

<style>
@import "./assets/styles/main.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: var(--main-bg-color);
  max-width: 100%;
  overflow-x: hidden;
}
</style>
